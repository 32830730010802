<template>
  <div>
    <b-row>
      <!-- Source Card -->
      <b-col lg="6" class="mb-2">
        <b-card class="mb-0" no-body>
          <b-row class="p-1">
            <b-col md="12">
              <h4 class="mb-0">Source</h4>
              <hr />
            </b-col>
            <b-col cols="12" lg="6" md="12">
              <b-form-group
                label="Campus"
                invalid-feedback="Please select a campus"
              >
                <v-select
                  v-model="sourceFilter.campus"
                  :options="campuses"
                  :reduce="(val) => val.id"
                  label="branch"
                  :clearable="false"
                  @input="LoadSourceClasses"
                  placeholder="Select campus"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6" md="12">
              <b-form-group
                label="Class"
                invalid-feedback="Please select a class"
              >
                <v-select
                  v-model="sourceFilter.class"
                  :options="sourceClassesOptions"
                  :reduce="(val) => val.id"
                  label="name"
                  :clearable="false"
                  @input="updateSourceSections"
                  placeholder="Select class"
                  :disabled="loadingSourceClasses || !sourceFilter.campus"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6" md="12">
              <b-form-group
                label="Section"
                invalid-feedback="Please select at least one section"
              >
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="sourceFilter.sections"
                  :options="sourceSections"
                  :reduce="(val) => val.id"
                  label="section"
                  placeholder="Select section"
                  :disabled="loadingSourceClasses || !sourceFilter.class"
                  @input="setSourceSections"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6" md="12">
              <b-form-group
                label="Gender"
                invalid-feedback="Please select gender"
              >
                <v-select
                  v-model="sourceFilter.gender"
                  :options="genderOptions"
                  :reduce="(val) => val.value"
                  label="name"
                  :clearable="false"
                  placeholder="Select gender"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3" sm="4">
              <b-button
                variant="primary"
                block
                :disabled="loading || destLoading || transferring"
                @click="validateSource"
              >
                <b-spinner v-if="loading" small />
                <span v-else>Show</span>
              </b-button>
            </b-col>
            <b-col md="12" class="d-flex flex-wrap align-items-center mt-1">
              <h5 class="mb-0">Total {{ students.length }} Students</h5>
              <b-button
                v-if="students.length > 0"
                variant="success"
                class="btn-icon ml-auto"
                :disabled="!isValidSource || transferring"
                @click="confirmTransfer"
                style="min-width: 140px"
              >
                <b-spinner v-if="transferring" small />
                <span v-else>Transfer Students ({{ selectedIDs.length }})</span>
              </b-button>
            </b-col>
          </b-row>

          <b-table
            ref="selectableTable"
            :items="students"
            :fields="fields"
            responsive
            hover
            show-empty
            :busy="loading"
            small
            class="mb-0"
            selectable
            select-mode="multi"
            @row-selected="onRowSelect"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>

            <template #head(index)="data">
              <div>
                <b-form-checkbox
                  inline
                  :checked="
                    selectedIDs.length > 0 &&
                    selectedIDs.length == students.length
                  "
                  :disabled="transferring"
                  @change="(e) => setSelectAll(e)"
                >
                  <span class="font-small-2">{{ data.label }}</span>
                </b-form-checkbox>
              </div>
            </template>

            <template #cell(index)="data">
              <b-form-checkbox
                inline
                :checked="selectedIDs.includes(data.item.id)"
                :disabled="transferring"
                @change="(e) => setSelected(e, data.index)"
              >
                <b-badge variant="light-primary">
                  {{ data.index + 1 }}
                </b-badge>
              </b-form-checkbox>
            </template>

            <template #cell(name)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="44"
                    :src="data.item.picture"
                    variant="light-primary"
                  />
                </template>

                <b-link class="font-weight-bold d-block text-nowrap mb-25">
                  <b-badge variant="light-primary">
                    {{ data.item.name }}
                  </b-badge>
                </b-link>
                <b-link class="font-weight-bold d-block text-nowrap mb-25">
                  <b-badge variant="light-primary">
                    F/N: {{ data.item.father_name }}
                  </b-badge>
                </b-link>
                <small class="text-muted">
                  <b-badge variant="light-primary">
                    GR -
                    {{
                      ["myskoolhpgs", "myskoolprofectus"].includes(reportDomain)
                        ? data.item.grNoLocal
                        : data.item.grNo
                    }}
                  </b-badge>
                </small>
                <b-link class="font-weight-bold d-block text-nowrap mt-25">
                  <b-badge variant="light-primary">
                    {{
                      ["myskoolhpgs", "myskoolprofectus"].includes(reportDomain)
                        ? `CID: ${data.item.grNo}`
                        : `ID: ${data.item.id}`
                    }}
                  </b-badge>
                </b-link>
              </b-media>
            </template>

            <template #cell(class)="data">
              <b-badge variant="light-primary">
                {{ data.item.meta_class }}
              </b-badge>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <!-- Destination Card -->
      <b-col lg="6">
        <b-card class="mb-0" no-body>
          <b-row class="p-1">
            <b-col md="12">
              <h4 class="mb-0">Destination</h4>
              <hr />
            </b-col>
            <b-col cols="12" lg="6" md="12">
              <b-form-group
                label="Campus"
                invalid-feedback="Please select a campus"
              >
                <v-select
                  v-model="destFilter.campus"
                  :options="campuses"
                  :reduce="(val) => val.id"
                  label="branch"
                  :clearable="false"
                  @input="LoadDestClasses"
                  placeholder="Select campus"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6" md="12">
              <b-form-group
                label="Class"
                invalid-feedback="Please select a class"
              >
                <v-select
                  v-model="destFilter.class"
                  :options="destClassesOptions"
                  :reduce="(val) => val.id"
                  label="name"
                  :clearable="false"
                  @input="updateDestSections"
                  placeholder="Select class"
                  :disabled="loadingDestClasses || !destFilter.campus"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6" md="12">
              <b-form-group
                label="Section"
                invalid-feedback="Please select a section"
              >
                <v-select
                  v-model="destFilter.section"
                  :options="destSections"
                  :reduce="(val) => val.id"
                  label="section"
                  :clearable="false"
                  placeholder="Select section"
                  :disabled="loadingDestClasses || !destFilter.class"
                  @input="validateDestination"
                />
              </b-form-group>
            </b-col>
            <!-- empty col to move button to next row -->
            <!-- <b-col cols="12" lg="6" md="12"></b-col> -->
            <!-- <b-col cols="12" lg="6" md="12">
              <b-form-group
                label="Gender"
                invalid-feedback="Please select gender"
              >
                <v-select
                  v-model="destFilter.gender"
                  :options="genderOptions"
                  :reduce="(val) => val.value"
                  label="name"
                  :clearable="false"
                />
              </b-form-group>
            </b-col> -->
            <!-- <b-col cols="12" md="3" sm="4">
              <b-button
                variant="primary"
                block
                :disabled="loading || destLoading || transferring"
                @click="validateDestination"
              >
                <b-spinner v-if="destLoading" small />
                <span v-else>Show</span>
              </b-button>
            </b-col> -->
            <b-col md="12" class="mt-1">
              <h5 class="mb-0">Total {{ destStudents.length }} Students</h5>
            </b-col>
          </b-row>

          <b-table
            :items="destStudents"
            :fields="fields"
            responsive
            hover
            show-empty
            :busy="destLoading"
            small
            class="mb-0"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>

            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(name)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="44"
                    :src="data.item.picture"
                    variant="light-primary"
                  />
                </template>

                <b-link class="font-weight-bold d-block text-nowrap mb-25">
                  <b-badge variant="light-primary">
                    {{ data.item.name }}
                  </b-badge>
                </b-link>
                <b-link class="font-weight-bold d-block text-nowrap mb-25">
                  <b-badge variant="light-primary">
                    F/N: {{ data.item.father_name }}
                  </b-badge>
                </b-link>
                <small class="text-muted">
                  <b-badge variant="light-primary">
                    GR -
                    {{
                      ["myskoolhpgs", "myskoolprofectus"].includes(reportDomain)
                        ? data.item.grNoLocal
                        : data.item.grNo
                    }}
                  </b-badge>
                </small>
                <b-link class="font-weight-bold d-block text-nowrap mt-25">
                  <b-badge variant="light-primary">
                    {{
                      ["myskoolhpgs", "myskoolprofectus"].includes(reportDomain)
                        ? `CID: ${data.item.grNo}`
                        : `ID: ${data.item.id}`
                    }}
                  </b-badge>
                </b-link>
              </b-media>
            </template>

            <template #cell(class)="data">
              <b-badge variant="light-primary">
                {{ data.item.meta_class }}
              </b-badge>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BCardBody,
  BButton,
  BMediaAside,
  BImg,
  BMedia,
  BSpinner,
  BBadge,
  BFormFile,
  BFormTextarea,
  BProgress,
  BProgressBar,
  BFormCheckbox,
  BSidebar,
  BForm,
  BInputGroupPrepend,
  BInputGroup,
  BTable,
  VBTooltip,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    BButton,
    BMediaAside,
    BImg,
    BMedia,
    BSpinner,
    BBadge,
    BFormFile,
    BFormTextarea,
    BProgress,
    BProgressBar,
    BFormCheckbox,
    BSidebar,
    BForm,
    BInputGroupPrepend,
    BInputGroup,
    BTable,
    BAvatar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      dataLoading: false,
      studentData: [],
      transferLoading: false,
      transferData: [],
      sourceClasses: [],
      destClasses: [],
      sourceClassesOptions: [],
      destClassesOptions: [],
      loading: false,
      destLoading: false,
      transferring: false,
      loadingSourceClasses: false,
      loadingDestClasses: false,
      campuses: [],
      sections: [],
      sourceSections: [],
      destSections: [],
      students: [],
      destStudents: [],
      genderOptions: [
        { value: "All", name: "All" },
        { value: "male", name: "Male" },
        { value: "female", name: "Female" },
      ],
      sourceFilter: {
        campus: 0,
        class: null,
        sections: [],
        gender: "All",
      },
      destFilter: {
        campus: 0,
        class: null,
        section: null,
        gender: "All",
      },
      fields: [
        { key: "index", label: "S.No" },
        { key: "name", label: "Name" },
        { key: "class", label: "class" },
      ],
      isValidDestination: false,
      isValidSource: false,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      selectedIDs: [],
      sourceSelectionAtLoad: {
        campus: 0,
        class: null,
        sections: [],
        gender: "All",
      },
      rights: {},
    };
  },
  created() {
    if (!this.$store.state.rights[this.$route.name]) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadCampuses();
    }
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    onRowSelect(ch) {
      this.selectedIDs = ch.map((el) => el.id);
      // console.log(this.selectedIDs);
    },
    setSelectAll(e) {
      // console.log(e);
      if (e) {
        this.$refs.selectableTable.selectAllRows();
      } else {
        this.$refs.selectableTable.clearSelected();
      }
    },
    setSelected(e, ind) {
      // console.log(e, ind);
      if (e) {
        this.$refs.selectableTable.selectRow(ind);
      } else {
        this.$refs.selectableTable.unselectRow(ind);
      }
    },

    async LoadCampuses() {
      const obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
    },

    async LoadSourceClasses() {
      this.sourceFilter.class = null;
      this.sourceFilter.sections = [];
      this.sourceSections = [];
      this.sourceClassesOptions = [];
      this.loadingSourceClasses = true;

      if (!this.sourceFilter.campus) {
        this.loadingSourceClasses = false;
        return;
      }

      const obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.sourceFilter.campus +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      const res = await this.get(obj);
      this.sourceClasses = res;
      this.sourceClassesOptions = this.sourceClasses.map((el) => el.cls);
      this.loadingSourceClasses = false;
    },

    async LoadDestClasses() {
      this.destFilter.class = null;
      this.destFilter.section = null;
      this.destSections = [];
      this.destClassesOptions = [];
      this.loadingDestClasses = true;

      if (!this.destFilter.campus) {
        this.loadingDestClasses = false;
        return;
      }

      const obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.destFilter.campus +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      const res = await this.get(obj);
      this.destClasses = res;
      this.destClassesOptions = this.destClasses.map((el) => el.cls);
      this.loadingDestClasses = false;
    },

    updateSourceSections() {
      this.sourceFilter.sections = [];
      this.sourceSections = [];
      const selectedClass = this.sourceClasses.find(
        (el) => el.cls.id === this.sourceFilter.class
      );
      if (
        selectedClass &&
        selectedClass.sections &&
        selectedClass.sections.length > 0
      ) {
        this.sourceSections = [
          { id: 0, section: "All Sections" },
          ...selectedClass.sections,
        ];
      }
    },

    updateDestSections() {
      this.destFilter.section = null;
      this.destSections = [];
      const selectedClass = this.destClasses.find(
        (el) => el.cls.id === this.destFilter.class
      );
      if (
        selectedClass &&
        selectedClass.sections &&
        selectedClass.sections.length > 0
      ) {
        this.destSections = selectedClass.sections;
      }
    },

    setSourceSections() {
      if (this.sourceFilter.sections.at(-1) == 0) {
        this.sourceFilter.sections = [0];
      } else {
        this.sourceFilter.sections = this.sourceFilter.sections.filter(
          (el) => el != 0
        );
      }
    },

    validateSource() {
      this.isValidSource =
        this.sourceFilter.campus &&
        this.sourceFilter.class &&
        this.sourceFilter.sections.length > 0 &&
        this.sourceFilter.gender;

      if (this.isValidSource) {
        this.LoadSourceStudents();
      } else {
        this.students = [];
      }
    },

    validateDestination() {
      this.isValidDestination =
        this.destFilter.campus &&
        this.destFilter.class &&
        this.destFilter.section &&
        this.destFilter.gender;

      if (this.isValidDestination) {
        this.LoadDestStudents();
      } else {
        this.destStudents = [];
      }
    },

    async LoadSourceStudents() {
      this.$refs.selectableTable.clearSelected();
      this.loading = true;
      // console.log("load source students", this.selectedIDs);
      try {
        const sections = this.sourceFilter.sections.includes(0)
          ? []
          : this.sourceFilter.sections;

        this.sourceSelectionAtLoad = {
          campus: this.sourceFilter.campus,
          class: this.sourceFilter.class,
          sections: [...sections],
          gender: this.sourceFilter.gender,
        };

        const obj = {
          url:
            this.$store.state.domain +
            "students/LoadDataClassWise?db=" +
            this.$store.state.userData.db +
            "&campusID=" +
            this.sourceFilter.campus +
            "&clsID=" +
            this.sourceFilter.class +
            "&secIDs=" +
            sections.join(",") +
            "&gender=" +
            this.sourceFilter.gender,
          token: this.$store.state.userData.token,
        };
        const res = await this.get(obj);
        // console.log(res);
        this.students = res.data || [];
      } catch (error) {
        console.error("Error loading students:", error);
        this.students = [];
      }
      this.loading = false;
    },

    async LoadDestStudents() {
      this.destLoading = true;
      try {
        const obj = {
          url:
            this.$store.state.domain +
            "students/LoadDataClassWise?db=" +
            this.$store.state.userData.db +
            "&campusID=" +
            this.destFilter.campus +
            "&clsID=" +
            this.destFilter.class +
            "&secIDs=" +
            this.destFilter.section +
            "&gender=" +
            this.destFilter.gender,
          token: this.$store.state.userData.token,
        };
        const res = await this.get(obj);
        this.destStudents = res.data || [];
      } catch (error) {
        console.error("Error loading destination students:", error);
        this.destStudents = [];
      }
      this.destLoading = false;
    },

    async confirmTransfer() {
      if (this.selectedIDs.length == 0) {
        this.$bvToast.toast("Please select the students to transfer", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
        return;
      }

      this.isValidDestination =
        this.destFilter.campus &&
        this.destFilter.class &&
        this.destFilter.section &&
        this.destFilter.gender;

      if (!this.isValidDestination) {
        this.$bvToast.toast(
          "Please select destination campus, class and section",
          {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          }
        );
        return;
      }

      const result = await Swal.fire({
        title: "Are you sure?",
        text:
          "You are about to transfer " + this.selectedIDs.length + " students",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, transfer!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      });

      if (result.isConfirmed) {
        await this.transferStudents();
      }
    },

    async transferStudents() {
      this.transferring = true;
      try {
        // TransferCampusBulk(string db, long cIDFrom, long cIDTo, long classIDFrom, long sectionIDFrom, long classIDTo, long sectionIDTo, long userID, [FromBody] List<long> sIDs)
        const obj = {
          url:
            this.$store.state.domain +
            "students/TransferCampusBulk?db=" +
            this.$store.state.userData.db +
            "&cIDFrom=" +
            this.sourceSelectionAtLoad.campus +
            "&cIDTo=" +
            this.destFilter.campus +
            "&classIDFrom=" +
            this.sourceSelectionAtLoad.class +
            "&sectionIDFrom=0&classIDTo=" +
            this.destFilter.class +
            "&sectionIDTo=" +
            this.destFilter.section +
            "&userID=" +
            this.$store.state.userData.userID,
          body: this.selectedIDs,
          token: this.$store.state.userData.token,
          message: "Students transferred successfully!",
          context: this,
        };

        const status = await this.post(obj);
        if (status) {
          // Refresh both lists
          await this.LoadSourceStudents();
          await this.LoadDestStudents();
        }
      } catch (error) {
        console.error("Error transferring students:", error);
      }
      this.transferring = false;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
